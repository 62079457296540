import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from 'layouts/Clean';
import { useForm, ValidationError } from '@formspree/react';

import {
  Title,
  Text,
  Scrollable,
  HorizontalScrollingLoop,
  Hero,
  SEO,
  TShapedBlock,
  SplitTextBlock,
  Button,
  SecretsBlock,
  ImageBlock,
} from 'components';
import heroImg from '../images/eventi/01-eventi.jpg';
import img02 from '../images/eventi/02-eventi.jpg';
import img03 from '../images/eventi/03-eventi.jpg';
import img04 from '../images/eventi/04-eventi.jpg';
import img05 from '../images/eventi/05-eventi.jpg';
import img06 from '../images/eventi/06-eventi.jpg';
import img09 from '../images/eventi/09-eventi.jpg';
import img31 from '../images/innovazione/31-lucchesi-innovazione.jpg';
import img01 from '../images/azienda/01-lucchesi-azienda.jpg';
import img08 from '../images/innovazione/31-lucchesi-innovazione.jpg';
import img52 from '../images/museo/52-lucchesi-museo.jpg';

const ContattiPage = ({ location }) => {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm('xdobyapk');

  const textileImages = [img02, img03, img04];

  const PAGE_PROPERTIES = {
    title: <Trans>Contatti</Trans>,
  };

  // const teamImages = [img04, img05, img06];

  return (
    <Layout location={location} hideContact>
      <SEO
        keywords={['Vivere in tessuto', 'Lucchesi']}
        title={t("Contattaci per una visita o un appuntamento.")}
        description={t("Scrivici per venire a visitarci. Nel cuore di Prato, nel cuore del tessile.")}
        location={location}
      />
      <section className="mt-12 md:mt-40">
        {' '}
        <Hero {...PAGE_PROPERTIES}></Hero>
      </section>

      <section className=" md:my-48 px-12 xl:px-36 min-h-[50vh] flex flex-col-reverse md:flex-row items-center relative">
        <div className="flex-1 relative">
          <div className="z-20 relative">
            <Title lower>
              Marco Lucchesi S.R.L. <br />
            </Title>
            <Title lower size="small">
              <font className="text-secondary">Tel </font> +39 0574 22245
              <br />
            </Title>
            <Title lower size="small">
              <font className="text-secondary">Mail </font>info@marcolucchesivivere.it
              <br />
            </Title>
            <Title lower size="small">
              Via Carradori 32
              <br />
              Prato, IT
            </Title>
          </div>
        </div>

        <div className="flex-1 w-full md:w-auto mb-12 px-0 md:pl-36 pr-4 xl:pr-24">
          {state.succeeded && (
            <div className="my-8 border border-black p-4">
              <Text>Grazie per aver inviato la richiesta! Ti contatteremo a breve!</Text>
            </div>
          )}
          <form className="flex flex-col items-start justify-start " onSubmit={handleSubmit}>
            <Trans>
              <Text>Nome e cognome *</Text>
            </Trans>
            <input
              name="name"
              className="border-b border-black py-2 mb-4 w-full"
              required
              placeholder="Inserisci il tuo nome"></input>
            <Trans>
              <Text>Email *</Text>
            </Trans>
            <input
              name="name"
              required
              className="border-b border-black py-2  mb-4 w-full"
              placeholder="Inserisci la tua mail"></input>
            <Trans>
              <Text>Numero di telefono *</Text>
            </Trans>
            <input
              required
              name="name"
              className="border-b border-black  py-2  mb-4 w-full"
              placeholder="Inserisci il tuo numero di telefono"></input>
            <Trans>
              <Text>Messaggio *</Text>
            </Trans>
            <input
              required
              name="name"
              className="border-b border-black  py-2  mb-4 w-full"
              placeholder="Inserisci il tuo messaggio"></input>
            <div className="flex items-center">
              <input type="checkbox" className="mr-2" required></input>

              <Text color="black" size="small">
                <Trans>Accetto la Privacy Policy</Trans>
              </Text>
            </div>
            <div className="my-4">
              <Button transparent type="submit" disabled={state.submitting}>
                <Trans>Invia la richiesta</Trans>
              </Button>
            </div>
          </form>
        </div>
      </section>
      <div className="flex flex-col md:flex-row mt-64 py-12">
        <div className="flex-1 flex">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11504.665391366152!2d11.100157471853334!3d43.87310492241803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132af61514e4bd9f%3A0x3905d368b5eb4bbd!2sMarco%20Lucchesi%20Srl!5e0!3m2!1sen!2sit!4v1649682013891!5m2!1sen!2sit"
            allowfullscreen=""
            loading="lazy"
            style={{ border: 'unset', width: '100%', minHeight: '33vh' }}
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <img className="flex-1 flex md:w-1/2" src={img52}></img>
      </div>
    </Layout>
  );
};

export default ContattiPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
